export const PHONE_PATTERN = new RegExp('^[0-9]{6,11}$');

export const PHONE_PATTERN_WITH_DELIMITER = new RegExp('^[\+]?[0-9-\]{8,15}$');

export const PHONE_PATTERN_WITH_PLUS = new RegExp('^[\+]?[0-9]{8,13}$');

// tslint:disable-next-line:max-line-length
const emailRegex = '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';

export const EMAIL_PATTERN = new RegExp(`^${emailRegex}$`);

export const EMAILS_PATTERN = new RegExp(`^${emailRegex} ?(, ?${emailRegex} ?)*$`);

export const PASSWORD_PATTERN = new RegExp(/^(?=^.{8,}$)((?=.*\d)|(?=.*\w+))(?!.*[\/\s])(?=.*[a-zA-z]).*$/);

export const LATIN_PATTERN = new RegExp(/^[a-zA-Z0-9]+$/);

// export const INPUT_NUMBER_PATTERN = new RegExp('^[0-9]');
export const INPUT_NUMBER_PATTERN = new RegExp('^-?[0-9]+([,.][0-9]+)?$');

export const INPUT_NUMBER_WITH_SPACES_PATTERN = new RegExp('^[0-9 ]+$');

export const EXPENSE_NUMBER_PATTERN = new RegExp(/(\d)(?=(\d\d\d)+([^\d]|$))/g);

export const INPUT_TIME_PATTERN = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');

export const URL_PATTERN = new RegExp(/^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/);
// tslint:disable-next-line:max-line-length
export const HTTP_URL_PATTERN = new RegExp(/(http(s)?:\/\/)(www)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/);
